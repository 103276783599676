<template>
  <div class="sec5-dsk hidden-md-and-down">
    <div class="sec5-cnt">
      <v-row>
        <v-col class="sec5-title">
          {{ $t("common.ctaBottom.text") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="sec5-sub">
          {{ $t("common.ctaBottom.subText") }}
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col>
          <v-btn
            large
            class="mint apply-btn-txt"
            :to="ctaPage??'/'"
          >
            {{ $t("common.ctaBottom.callToAction") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-img
      src="/img/dashboard/mobile-app-mockup.png"
      :class="xlOnly ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
    ></v-img>
  </div>
</template>
<script>
  export default {
    props: ['ctaPage'],
    computed: {
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
    }

  }
</script>

<style scoped lang="scss">

.sec5-dsk {
  position: relative;
  height: 356px;
  background: url('/img/dash-pattern.jpg');
}
.sec5-dsk-phone {
  position: absolute;
  left: 726px;
  top: -78px;
}
.sec5-xl-phone {
  position: absolute;
  left: 1100px;
  top: -78px;
}
.sec5-cnt {
  position: absolute;
  width: 516px;
  height: 157px;
  left: 794px;
  top: 75px;
  left: 214px;
}
.sec5-title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  letter-spacing: -0.424019px;
  /* Color Palette/White (100%) */
  color: #FFFFFF;
}
.sec5-sub {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #FFFFFF;
}
</style>
