<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="">
      <hero-component
        align="center"
        background-url="/img/news/hero-20230323.jpg"
      >
        {{ $t("about.news.cta.text") }}
      </hero-component>
      <div class="news-section">
        <v-container>
          <v-row
            align="center"
          >
            <v-col cols="12">
              <h1 class="text-center mb-5 text-h2">
                {{ $t("about.news.newsTitle") }}
              </h1>
            </v-col>

            <v-row
              v-for="(news,index) in $t('about.news.pressRelease')"
              :key="index"
              md="12"
              xl="12"
              offset-md="0"
              offset-xl="2"
              order="2"
              order-md="1"
              class=""
            >
              <v-col
                md="5"
                order="1"
                order-md="0"
                cols="11"
                offset="1"
              >
                <h1 class="news-title">
                  {{ news.headline }}
                </h1>
                <p>
                  {{ news.subHeadline }}
                </p>
                <v-btn
                  class="learn-btn"
                  :href="`/about/news/details/${news.link}`"
                >
                  <span class="lrn-btn-txt">
                    {{ $t("about.news.readMore") }}
                  </span>
                </v-btn>
              </v-col>
              <v-col
                class="text-center"
                md="4"
                order="0"
                order-md="1"
                offset="1"
                cols="11"
              >
                <img
                  :style="`max-width: ${index===0?'80%':'100%'}`"
                  :src="news.logo"
                  class="w-100"
                />
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </div>
      <!-- <div class="section3-desk">
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            class="s3-title d-flex justify-center"
          >
            All Blanket News
          </v-col>
          <v-col
            cols="7"
            class="s3-text text-center"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet vitae, felis nunc, egestas eu mauris vel tellus donec.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            v-for="item in newsTiles"
            :key="item"
            md="4"
          >
            <v-card class="v-card-news">
              <v-img
                height="175"
                :src="item.image"
                class="w-100"
                position="top"
              ></v-img>
              <v-card-title class="justify-center my-5">
                <h1>
                  {{ item.title }}
                </h1>
              </v-card-title>
              <v-card-text class="text-center">
                <p class="px-5">
                  {{ item.description }}
                </p>
              </v-card-text>
              <v-card-actions class="justify-center pb-5">
                <v-btn
                  class="learn-btn"
                  :href="news.link"
                >
                  <span class="lrn-btn-txt">
                    READ MORE
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
      <call-to-action cta-page="/quote"></call-to-action>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'
  import CallToAction from '@/components/CallToAction.vue'

  export default {
    components: {
      CallToAction,
      HeroComponent
    },
    data () {
      return {
        news: {
          title: this.$t('about.news.headline'),
          description: this.$t('about.news.subHeadline'),
          image: '/img/BNC-Logo_Stack-02.png',
          link: '/about/news/details/blanket-acquires-bnc-insurance'
        },
      }
    },
    computed: {
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      }
    }

  }
</script>

<style lang="scss" scoped>

.section2-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
}

.s2-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.s2-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.s2-text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.s2-big-text {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.section3-desk{
  height: 900px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
  padding-top: 75px;
  padding-left: 165px;
  padding-right: 165px;
}
// have to work on mobile design, not approved yet
@media screen and (max-width: 1250px) {
.section3-desk{
  display: none;
}
}

.s3-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s3-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;
/* identical to box height, or 114% */

text-align: center;

/* white */

color: #FFFFFF;
}

.s3-text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #FFFFFF;
}

.section4-desk {
  padding-top: 79px;
  padding-left: 131px;
  padding-right: 131px;
  padding-bottom: 146px;
  background: #FFFFFF;
}

.s4-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.exec-name {
  font-family: 'Sora';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 110%;
/* or 33px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.424019px;
text-align: center;
color: #AAF0C1;
}

.exec-title {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 150%;
/* or 24px */

text-align: center;

/* white */

color: #FFFFFF;
}

.exec-card-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
letter-spacing: -0.42px;
color: #FFFFFF;
}

.apply-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
letter-spacing: 0.00892857em;
color: #292F36 !important;
}

.section6-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
  padding-bottom: 87px;
}

.news-section {
padding: 5rem 4rem 7rem 4rem;
background: #FFFFFF;
}
.news-section h1 {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #292F36;
}
.news-section h1.news-title {
  color: #005F6C;
}
.news-section p {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.424019px;
    color: #292F36;
    margin: 1rem 0rem;
}

.learn-btn {
width: 137px;
height: 33px;
border: 1.37127px solid #005F6C;
border-radius: 5.48507px;
background: #FFFFFF !important;
}
.lrn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16.5px;
line-height: 125%;
/* identical to box height, or 21px */
padding-top: 5px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
/* midnight eagle green */
color: #005F6C;
}

.v-card-news {
    border-radius: 10px;
}

.v-card-news  h1{
  width: 320px;
height: 33px;

font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #00A1B7;
}
.v-card-news p {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 125%;
/* or 25px */

text-align: center;
letter-spacing: -0.202611px;

/* gunmetal */

color: #292F36;
}
/*---------------------TABLET---------------------*/
.section1-tablet {
  position: relative;
  height: 400px;
  // background-image: url('/img/news/hero.jpg');
  background-image: url('/img/news/hero-20230323.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-tablet {
  position:absolute;
  top: 250px;
  left: 50px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.section2-tablet {
  background: #FFFFFF;
  padding-top: 93px;
}

.s2-title-tablet {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.section3-tablet{
  height: 590px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;

}

.section4-tablet{
  padding-top: 79px;
  background: #FFFFFF;
}
.sec5-tablet {
  height: 300px;
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-tablet {
  background: #FFFFFF;
  padding-top: 93px;
  padding-bottom: 87px;
}

/*---------------------MOBILE---------------------*/
.section1-mobile {
  position: relative;
  height: 385px;
  background-image: url('/img/news/hero-m.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.section2-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  background-position: center;
  background-size: cover;
}

.s2-title-tablet {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.section3-mobile{
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
}
.section4-tablet{
  padding-top: 79px;
  background: #FFFFFF;
}
.sec5-mobile {
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  padding-top: 93px;
  padding-bottom: 87px;
}

.snow-white {
  background-color: #FFFFFF;
}

.s2-big-text-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.511589px;
color: #005F6C;
  }

  .s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.white-bar-tablet {
  height: 110px;
  background: #FFFFFF;
}

@media screen and (max-width: 520px) {
  .news-section{
    padding: 5rem 2rem 7rem 2rem;
  }
}
</style>
